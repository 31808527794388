import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";

import { getFirestore } from "firebase/firestore";
// Initialize Cloud Firestore and get a reference to the service
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAKns7YwTutrt_0J7R1FgsdOB1Vqsp-KQw",
    authDomain: "egsac-carting.firebaseapp.com",
    projectId: "egsac-carting",
    storageBucket: "egsac-carting.appspot.com",
    messagingSenderId: "697401828903",
    appId: "1:697401828903:web:067ef0e6de1637bec69002"
};

let app = initializeApp(firebaseConfig);

const db = getFirestore(app);


const auth = getAuth();

export { auth ,db};
